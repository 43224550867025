import { defineStore } from 'pinia';
import { ref } from 'vue';
import { DashboardData, DashboardUserGroupData } from '@/dashboard/utils/types';
import {
  getBoardsIntegratedDashboardControllerAxios,
  getGroupsIntegratedDashboardControllerAxios,
  patchBoardIntegratedDashboardControllerAxios,
  patchFavoriteBoardIntegratedDashboardControllerAxios,
  patchGroupIntegratedDashboardControllerAxios,
  setCustomReportIntegratedDashboardControllerAxios,
} from '@/openapi/metaV6/api/integrated-dashboard-controller-api';
import {
  IntegrateDashboardComponentExtends,
  IntegrateDashboardPatchResponse,
} from '@/openapi/metaV6/model';
import { PromiseAxiosResponse } from '@/worker/commands/config/apiInstance';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

interface IntegratedDashboardItem {
  dashboardGroupId?: number;
  dashboardId?: number;
  dashboardName: string;
  deleted: boolean;
  preset: boolean;
  share: boolean;
  startScreen: boolean;
  description?: string;
  image?: string;
  value?: string;
}

interface IntegratedFavoriteDashboardItem {
  dashboardId: number;
  deleted: boolean;
  favoriteId?: number;
}

export const useDashboardListViewStore = defineStore('dashboardDashboardListView', () => {
  const dashboardUserGroupList = ref<DashboardUserGroupData[]>([]);
  const dashboardList = ref<DashboardData[]>([]);

  const fetchDashboardUserGroupList = async () => {
    try {
      const { data } = await getGroupsIntegratedDashboardControllerAxios({
        frameName: FRAME_NAMES.DASHBOARD.DASHBOARD_USER_GROUP,
      });
      dashboardUserGroupList.value =
        data?.data?.map((group) => ({
          dashboardGroupId: group.dashboardGroupId,
          dashboardGroupName: group.dashboardGroupName ?? '',
        })) ?? [];
    } catch (e) {
      console.log(e);
    }
  };

  const fetchDashboardList = async (isImage = false) => {
    try {
      const { data } = await getBoardsIntegratedDashboardControllerAxios({
        frameName: FRAME_NAMES.DASHBOARD.DASHBOARD_LIST,
        includeImage: isImage,
      });
      dashboardList.value =
        (data?.data?.map((dashboard) => ({
          dashboardId: dashboard.dashboardId ?? -1,
          dashboardGroupId: dashboard.dashboardGroupId,
          dashboardName: dashboard.dashboardName ?? '',
          description: dashboard.description,
          image: dashboard.image,
          preset: dashboard.preset ?? false,
          share: dashboard.share ?? false,
          sharePermission: {
            shareUsers: dashboard.sharePermission?.shareUsers,
            edit: dashboard.sharePermission?.edit ?? false,
            delete: dashboard.sharePermission?.delete ?? false,
            shared: dashboard.sharePermission?.shared ?? false,
          },
          startScreen: dashboard.startScreen ?? false,
          favoriteId: dashboard.favoriteId,
          createUser: dashboard.createUser ?? false,
          creator: {
            name: dashboard.createUserName ?? '',
            activeId: dashboard.createUserActiveId ?? '',
            profileImage: dashboard.createUserProfileImage ?? '',
          },
          lastModified: dashboard.lastModified,
          menuPaths: dashboard.menuPaths,
          analysisMenuPaths: dashboard.analysisMenuPaths,
          featuredReport: dashboard.featuredReport ?? false,
          customReport: dashboard.customReport ?? false,
          iconId: dashboard.iconId ?? '',
        })) as DashboardData[]) ?? [];
    } catch (e) {
      console.log(e);
    }
  };

  const updateDashboardUserGroup = async (
    { dashboardGroupId, dashboardGroupName }: DashboardUserGroupData,
    deleted = false,
    frameName = '',
  ) => {
    try {
      await patchGroupIntegratedDashboardControllerAxios({
        request: {
          dashboardGroupId,
          dashboardGroupName,
          deleted,
        },
        frameName,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const addDashboardList = async (
    dashboards: IntegrateDashboardComponentExtends[],
  ): PromiseAxiosResponse<IntegrateDashboardPatchResponse> => {
    return patchBoardIntegratedDashboardControllerAxios({
      request: {
        dashboards,
      },
    });
  };

  const updateDashboardList = async (
    dashboards: IntegratedDashboardItem[],
    isImage = false,
    frameName?: string,
  ) => {
    if (!dashboards.length) {
      return;
    }

    await patchBoardIntegratedDashboardControllerAxios({
      request: {
        dashboards,
      },
      frameName,
    });

    await fetchDashboardList(isImage);
  };

  const deleteDashboardList = async (dashboardIds: number[], isImage = false) => {
    const deletedDashboards: IntegratedDashboardItem[] = dashboardList.value.reduce(
      (acc: IntegratedDashboardItem[], dashboard) => {
        if (dashboard.dashboardId && dashboardIds.includes(dashboard.dashboardId)) {
          acc.push({
            ...dashboard,
            dashboardGroupId: -1,
            deleted: true,
          });
        }
        return acc;
      },
      [],
    );

    await updateDashboardList(
      deletedDashboards,
      isImage,
      FRAME_NAMES.DASHBOARD.DASHBOARD_LIST_UPDATE,
    );
  };

  const moveGroupForDashboardList = async (dashboardIds: number[], newGroupId: string | null) => {
    const movedDashboards: IntegratedDashboardItem[] = [];
    dashboardList.value.forEach((dashboard) => {
      if (dashboard.dashboardId && dashboardIds.includes(dashboard.dashboardId)) {
        movedDashboards.push({
          ...dashboard,
          dashboardGroupId: newGroupId ? +newGroupId : undefined,
          deleted: false,
        });
      }
    });

    await updateDashboardList(
      movedDashboards,
      false,
      FRAME_NAMES.DASHBOARD.DASHBOARD_USER_GROUP_MOVE,
    );
  };

  const setStartScreenDashboard = async (dashboardId: number) => {
    const startScreenDashboards: IntegratedDashboardItem[] = [];
    dashboardList.value.forEach((dashboard) => {
      if (dashboard.dashboardId && dashboard.startScreen) {
        startScreenDashboards.push({
          ...dashboard,
          startScreen: false,
          deleted: false,
        });
      } else if (dashboard.dashboardId && dashboard.dashboardId === dashboardId) {
        startScreenDashboards.push({
          ...dashboard,
          startScreen: true,
          deleted: false,
        });
      }
    });

    await updateDashboardList(startScreenDashboards, false, FRAME_NAMES.DASHBOARD.START_SCREEN_SET);
  };

  const updateFavoriteDashboardList = async (dashboardIds: number[]) => {
    if (!dashboardIds.length) {
      return;
    }

    const dashboards: IntegratedFavoriteDashboardItem[] = [];
    dashboardList.value.forEach((dashboard) => {
      if (dashboard.dashboardId && dashboardIds.includes(dashboard.dashboardId)) {
        dashboards.push({
          dashboardId: dashboard.dashboardId,
          favoriteId: dashboard.favoriteId,
          deleted: !!dashboard.favoriteId,
        });
      }
    });

    try {
      await patchFavoriteBoardIntegratedDashboardControllerAxios({
        request: {
          dashboards,
        },
        frameName: FRAME_NAMES.DASHBOARD.FAVORITE_DASHBOARD_UPDATE,
      });
      await fetchDashboardList();
    } catch (e) {
      console.log(e);
    }
  };

  const setReportDashboard = async (dashboardId: number, customReport: boolean) => {
    try {
      await setCustomReportIntegratedDashboardControllerAxios({
        request: {
          dashboardId,
          customReport,
        },
        frameName: FRAME_NAMES.DASHBOARD.REPORT_DASHBOARD_SET,
      });

      await fetchDashboardList();
    } catch (e) {
      console.log(e);
    }
  };

  return {
    dashboardUserGroupList,
    dashboardList,
    fetchDashboardList,
    fetchDashboardUserGroupList,
    updateDashboardUserGroup,
    addDashboardList,
    updateDashboardList,
    deleteDashboardList,
    moveGroupForDashboardList,
    setStartScreenDashboard,
    updateFavoriteDashboardList,
    setReportDashboard,
  };
});
